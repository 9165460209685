<template>
  <footer class="footer" :class="{ limited: isLimited }">
    <div class="max-container py-10 px-6 md:px-12">
      <div class="flex flex-col space-y-6 xl:space-y-0 xl:flex-row items-center justify-between">
        <Icon class="h-5 w-auto" name="logo" v-if="!isLimited" />
        <div
          class="flex items-center flex-col xl:flex-row text-sm text-gray-600 xl:space-x-6 space-y-8 xl:space-y-0"
          :class="isLimited ? 'w-full justify-between' : ''"
        >
          <div class="footer-links">
            <router-link v-for="link in links" :key="link" :to="link.route" target="_blank">{{ link.name }}</router-link>
            <a href="http://support.medusadistribution.com/en/" target="_blank">
              Help Center
            </a>
            <a href="callto:+19738081101">973-808-1101</a>
          </div>
          <p class="text-sm text-gray-400 text-center">&copy; {{ currentYear }} Medusa Distribution. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const links = [
  {
    name: "Privacy Policy",
    route: "/privacy-policy",
  },
  {
    name: "Terms & Conditions",
    route: "/terms-and-conditions",
  },
];

export default {
  name: "Footer",
  props: {
    isLimited: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // console.log($(".intercom- launcher").children('div').text());

    if (localStorage.getItem("username")) {
      window.intercomSettings = {
        app_id: "e5yhpdhn",
        name: localStorage.getItem("username"), // Full name
        email: localStorage.getItem("email"), // Email address
        user_hash: localStorage.getItem("user_hash"), // User Hash
        custom_launcher_selector: "#openChat",
      };
    } else {
      window.intercomSettings = {
        app_id: "e5yhpdhn",
        custom_launcher_selector: "#openChat",
      };
    }

    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/e5yhpdhn'
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/e5yhpdhn";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  },
  setup() {
    const currentYear = new Date().getFullYear();
    return {
      currentYear,
      links,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  @apply bg-white 2xl:pl-60;

  &.limited {
    @apply 2xl:pl-0;
  }

  .footer-links {
    @apply grid grid-cols-2 sm:flex items-center gap-6 text-center;

    a {
      @apply text-sm text-brand-secondary text-opacity-70 hover:text-opacity-100 hover:underline;
    }
  }
}
</style>
